<template>
    <DxFileManager
        ref="fileManager"
        :toolbar="toolbar"
        :contextMenu="contextMenu"
        :permissions="permissions"
        :file-system-provider="fileSystemProvider"
        :height="height"
        :on-current-directory-changed="onDirectoryChanged"
        :on-current-directory-creating="onDirectoryCreating"
        :on-item-deleting="onItemDeleting"
        :on-toolbar-item-click="onToolbarItemClick"
        :on-item-renamed="onItemRenamed"
        :on-context-menu-item-click="onContextMenuItemClick"
        :on-item-moving="onItemMoving"
        :on-selected-file-opened="onSelectedFileOpened"
        :customize-thumbnail="customizeIcon"
        :customize-detail-columns="customizeColumn"
    >
    </DxFileManager>
</template>

<script>
import 'devextreme/dist/css/dx.light.css';
import {DxFileManager} from 'devextreme-vue/file-manager';
import ObjectFileSystemProvider from 'devextreme/file_management/object_provider';

import {locale, loadMessages} from 'devextreme/localization';
import trMessages from '@/assets/plugin/dev-express/locales/tr.json';
loadMessages(trMessages);

export default {
    name: "index",
    emit: [
        "directoryChanged",
        "directoryCreating",
        "directoryDeleting",
        "directoryRenamed",
        "directoryMoving",
        "fileDeleting",
        "fileRenamed",
        "fileMoving",
        "fileOpened",
        "toolbarItemClick",
        "contextMenuItemClick",
    ], //extraMethods => fileUploadEvent
    props: {
        toolbar: {
            type: Object, default: () => {
                return {
                    items: ['showNavPane', 'create', 'upload','switchView', {name: 'separator', location: 'after'}, 'refresh'],
                    fileSelectionItems: ['download', 'separator', 'move', 'copy', 'rename', 'separator', 'delete', 'clearSelection', {name: 'separator', location: 'after'}, 'refresh']
                }
            }
        },
        contextMenu: {
            type: Object, default: () => {
                return {
                    items: ['create', 'upload', 'rename', 'move', 'copy', 'delete', 'refresh', 'download'],
                }
            }
        },
        permissions: {
            type: Object, default: () => {
                return {create: true, upload: true, move:true, delete:true, rename: true}
            }
        },
        customUpload: {type: Boolean, default: true},
        items: {type: Array, required: true},
        height: {type: Number, default: 450},
        localization: {type: String, default: "tr"},
        nameExpr: {type: String, default: "title"},
        isDirectoryExpr: {type: String, default: "is_directory"},
        dateModifiedExpr: {type: String, default: "updated_at"},
        keyExpr: {type: String, default: "key"},
        itemsExpr: {type: String, default: "children"},
        customizeIcon: {type: Function, default: undefined},
        customizeColumn: {type: Function, default: undefined}
    },
    components: {
        DxFileManager,
    },
    data(){
        return {
            locale: locale,
        }
    },
    computed: {
        fileManager() {
            return this.$refs.fileManager.instance;
        },
        fileSystemProvider(){
            return new ObjectFileSystemProvider({
                data: this.items,
                nameExpr: this.nameExpr,
                itemsExpr: this.itemsExpr,
                isDirectoryExpr: this.isDirectoryExpr,
                dateModifiedExpr: this.dateModifiedExpr
            })
        }
    },
    created(){
        if(this.customUpload) {
            let uploadIndex = this.toolbar.items.indexOf('upload');
            this.toolbar.items[uploadIndex] = {
                name: 'fileUpload',
                options: {
                    text: this.$t('common.fileUpload'),
                    icon: 'upload'
                }
            };

            let contextUploadIndex = this.contextMenu.items.indexOf('upload');
            this.contextMenu.items[contextUploadIndex] = {
                name: 'fileUpload',
                text: this.$t('common.fileUpload'),
                icon: 'upload'
            }
        }

        if(this.localization) {
            this.setLocale(this.localization)
        }
    },
    methods: {
        setLocale(locale){
            this.locale(locale);
        },
        onDirectoryChanged(e){
             this.$emit("directoryChanged", e);
        },
        onDirectoryCreating(e) {
            this.$emit("directoryCreated", e);
        },
        onItemDeleting(e) {
            this.$emit((e.item.isDirectory ? "directoryDeleting" : "fileDeleting"), e, e.item.dataItem);
        },
        onToolbarItemClick(e) {
            this.$emit("toolbarItemClick", e);
            this.commonCustomEvents(e);
        },
        onItemRenamed(e){
            this.$emit((e.sourceItem.isDirectory ? "directoryRenamed" : "fileRenamed"), e.itemName, e.sourceItem.dataItem);
        },
        onContextMenuItemClick(e){
            this.$emit("contextMenuItemClick", e);
            this.commonCustomEvents(e);
        },
        commonCustomEvents(e){
            let itemData = e.itemData.name ?? e.itemData;

            if(itemData){
                this.$emit((this.sprintf('%sEvent', [itemData])), e, this.fileManager.getCurrentDirectory());
            }
        },
        onItemMoving(e) {
            let destinationDirectory = e.destinationDirectory;
            let item = e.item;
            this.$emit((item.isDirectory ? "directoryMoving" : "fileMoving"), e, item.dataItem, destinationDirectory.dataItem);
        },
        onSelectedFileOpened(e){
            this.$emit("fileOpened", e.file);
        }
    },
    watch: {
        items: function () {
            if(this.$refs.fileManager && this.fileManager) {
                setTimeout(() => {
                    this.fileManager.refresh();
                }, 500);
            }
        },
    }
}
</script>

<style>
</style>