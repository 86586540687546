<template>
    <custom-file-manager
        ref="fileManagerComponent"
        :items="directoryTable.data"
        :onDirectoryChanged="onDirectoryChanged"
        :onDirectoryCreating="onDirectoryCreating"
        :onDirectoryDeleting="onDirectoryDeleting"
        :onDirectoryRenamed="onDirectoryRenamed"
        :onDirectoryMoving="onDirectoryMoving"
        :onFileDeleting="onFileDeleting"
        :onFileOpened="onFileOpened"
        :onFileRenamed="onFileRenamed"
        :onFileMoving="onFileMoving"
        :onFileUploadEvent="onFileUploadEvent"
        :onRefreshEvent="loadDirectoryTree"
        :customizeIcon="onCustomizeIcon"
        :customizeColumn="onCustomizeColumn"
        height="calc(100vh - 250px)"
    ></custom-file-manager>
    <div class="modal fade" id="kt_modal_video" ref="videoModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onVideoSubmit()" :model="form.data" ref="videoForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.video.upload.cols.video.title') }}</label>
                                <el-form-item>
                                    <el-upload :file-list="form.uploadList" accept="video/*" multiple :on-change="handleChangeFile" :on-remove="handleChangeFile" :auto-upload="false" :limit="5">
                                        <el-button type="primary">{{ $t('common.chooseFile') }}</el-button>
                                    </el-upload>
                                    <div class="fv-row d-flex mt-2" v-for="(file, fileIndex) in form.uploadList" :key="fileIndex">
                                        <label class="min-w-125px col-form-label fs-6 fw-bold mb-2">{{ sprintf($t('pages.video.upload.videoTitlePattern'), [(fileIndex + 1)]) }}:</label>
                                        <div class="w-100">
                                            <el-input v-model="form.uploadList[fileIndex].name" type="text"/>
                                        </div>
                                    </div>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.video.upload.cols.group') }}</label>
                                <el-form-item prop="group_id" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="form.data.group_id" :placeholder="$t('common.chooseSelect')" filterable class="w-100">
                                        <el-option v-for="(group, groupIndex) in videoGroups" :key="groupIndex" :value="group.id" :label="group.name"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.video.upload.cols.option') }}</label>
                                <el-form-item>
                                    <div class="lh-1 form-check form-check-custom form-check-solid my-2">
                                        <input class="form-check-input" type="checkbox" v-model="form.data.convert_hls" id="exportHLS">
                                        <label class="form-check-label" for="exportHLS">
                                            {{$t('pages.video.upload.option.convertHls') }}
                                        </label>
                                    </div>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomFileManager from "@/components/custom-file-manager";

export default {
    name: "index",
    components: {
        CustomFileManager
    },
    data() {
        return {
            directoryTable: {
                data: [],
                loading: false,
            },
            form: {
                title: '',
                uploadList: [],
                loading: false,
                updateStatus: false,
                data: {}
            },
            filesPath: 'media/svg/files'
        }
    },
    created() {
        this.loadDirectoryTree();
    },
    computed: {
        videoGroups() {
            return this.$store.state.video.group.table.data;
        },
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("pages.directory.title"));
        this.$store.dispatch('video/group/get', {
            page: { pageSize: 9999 }
        });
    },
    methods: {
        loadDirectoryTree() {
            this.directoryTable.loading = true;

            this.axios.get(this.endpoint.get('directory_tree')).then((response) => {
                let data = response.data.data;
                this.directoryTable.data = data;

                this.checkVideoWithoutFolder();
            }).finally(() => {
                this.directoryTable.loading = false;
            });
        },
        onDirectoryChanged(e) {
            let directory = e.directory;
            let dataItem = directory.dataItem ?? undefined;

            if (dataItem && dataItem.videos_count > 0 && !dataItem.childrenLoaded) {
                this.axios.get(this.endpoint.get('video'), {
                    params: {
                        directory_id: dataItem.id,
                        sort: 'created_at:desc',
                        page_limit: 100
                    }
                }).then((response) => {
                    let data = response.data.data;

                    if (!dataItem.children) {
                        dataItem.children = [];
                    }

                    data.forEach((video) => {
                        video.is_directory = false;
                        dataItem.children.push(video);
                    });

                    dataItem.childrenLoaded = true;

                    this.refreshFileManager();
                });
            }
        },
        onDirectoryCreating(e) {
            let directoryItem = e.parentDirectory.dataItem;

            if(directoryItem && directoryItem.id == 0) {
                e.errorText = this.$t('pages.directory.warnings.cantCreatedWithoutFolder');
                e.cancel = true;

                return;
            }

            this.form.data = {
                parent_id: (directoryItem && directoryItem.id ? directoryItem.id : undefined),
                title: e.name
            };

            this.directoryRequest(() => {
                this.loadDirectoryTree();
            });
        },
        directoryRequest(successEvent = undefined) {
            let axios = null;
            if (this.form.data.id) {
                axios = this.axios.put(this.endpoint.get('directory') + '/' + this.form.data.id, this.form.data);
            } else {
                axios = this.axios.post(this.endpoint.get('directory'), this.form.data);
            }

            if(axios){
                axios.then(response => {
                    this.onResponse(response.data, successEvent, () => {
                        this.loadDirectoryTree();
                    }, false, false);
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.loadDirectoryTree();
                    });
                });
            }
        },
        onDirectoryDeleting(e, data) {
            if(data.id == 0) {
                e.errorText = this.$t('pages.directory.warnings.cantDeletedWithoutFolder');
                e.cancel = true;

                return;
            }

            this.$store.dispatch("directory/delete", {
                id: [data.id]
            }).then((successDeleted) => {
                if(!successDeleted){
                    this.loadDirectoryTree();
                }
            });
        },
        onDirectoryRenamed(name, data){
            this.form.data = {
                id: data.id,
                title: name,
            };

            this.directoryRequest();
        },
        onDirectoryMoving(e, item, destinationDirectory){
            if(destinationDirectory.id == 0) {
                e.errorText = this.$t('pages.directory.warnings.cantMovedFolderToWithoutFolder');
                e.cancel = true;

                return;
            }

            this.form.data = {
                id: item.id,
                parent_id: destinationDirectory ? destinationDirectory.id : null,
            };

            this.directoryRequest();
        },
        onFileDeleting(e, data) {
            this.$store.dispatch("video/delete", {
                id: [data.id]
            }).then((successDeleted) => {
                if(!successDeleted){
                    this.loadDirectoryTree();
                }
            });
        },
        onFileOpened(file){
            this.trustedWindowOpen(this.sprintf('/video/detail/%d', [file.dataItem.id]), '_blank')
        },
        onFileRenamed(name, data){
            this.form.data = {
                id: data.id,
                title: name,
            };

            this.videoRequest();
        },
        onFileMoving(e, item, destinationDirectory){
            if(!destinationDirectory){
                e.errorText = this.$t('pages.directory.warnings.cantMovedHomeDirectory');
                e.cancel = true;

                return;
            }

            if(destinationDirectory.id == 0) {
                e.errorText = this.$t('pages.directory.warnings.cantMovedFileToWithoutFolder');
                e.cancel = true;

                return;
            }

            this.form.data = {
                id: item.id,
                directory_id: destinationDirectory.id,
            };

            this.videoRequest();
        },
        onFileUploadEvent(event, currentDirectory){
            let dataItem = currentDirectory.dataItem;

            if(!this.directoryTable.data.length || !dataItem || dataItem.id == 0){
                this.$swal.fire({
                    icon: 'warning',
                    title: this.$t('messages.warning'),
                    text: this.$t('pages.directory.warnings.requiredDirectory'),
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: this.$t('btn.close')
                });
                return;
            }

            this.form.uploadList = [];

            this.form.data = {
                convert_unsupported: true,
                convert_hls: true,
                directory_id: dataItem.id
            };
            this.form.title = this.$t("pages.video.upload.title");
            this.showModal(this.$refs.videoModal);
        },
        onCustomizeIcon(fileSystemItem){
            let dataItem = fileSystemItem.dataItem;

            if (fileSystemItem.isDirectory) {
                return this.filesPath + "/folder.svg";
            } else {
                if(dataItem && dataItem.image){
                    return dataItem.image.direct_link;
                } else {
                    return this.filesPath + "/video.svg";
                }
            }
        },

        onCustomizeColumn(columns) {
            columns[0].width = 45;
            delete columns[2].hidingPriority;
            delete columns[3].hidingPriority;

            columns.splice(2, 0, {
                caption: this.$t('pages.directory.videoCount'),
                width: 80,
                dataField: 'videos_count',
                name: 'videos_count',
            });

            return columns;
        },
        refreshFileManager() {
            this.$refs.fileManagerComponent.fileManager.refresh();
        },
        videoRequest(successEvent = undefined) {
            let axios = null;
            if (this.form.data.id) {
                axios = this.axios.put(this.endpoint.get('video') + '/' + this.form.data.id, this.form.data);
            } else {
                axios = this.axios.post(this.endpoint.get('video'), this.form.data);
            }

            if(axios){
                axios.then(response => {
                    this.onResponse(response.data, successEvent, () => {
                        this.loadDirectoryTree();
                    }, false, false);
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.loadDirectoryTree();
                    });
                });
            }
        },
        onVideoSubmit(){
            this.$refs.videoForm.validate((valid) => {
                if(valid) {
                    if(!(this.form.uploadList.length > 0)){
                        this.$notify({
                            type: 'warning',
                            title: this.$t("messages.warning"),
                            message: this.$t("pages.video.upload.warnings.requiredFile"),
                        })
                        return false;
                    }

                    this.form.loading = true;

                    let multipleRequest = this.form.uploadList.map((file) => {
                        const formData = new FormData();
                        formData.append("file", file.raw);
                        formData.append("title", file.name);

                        for (let key in this.form.data){
                            formData.append(key, (typeof(this.form.data[key]) == 'boolean' ? (Number(this.form.data[key])) : this.form.data[key]));
                        }

                        return this.axios.post(this.endpoint.get('video'), formData).catch((err) => {
                            this.axios.post(this.endpoint.get('log_register', 'system'), {
                                error: err.response,
                                upload_content: file
                            });
                        });
                    });

                    this.requestVideoUpload(multipleRequest).then((response) => {
                        this.onResponse(response, () => {
                            this.loadDirectoryTree();
                            this.hideModal(this.$refs.videoModal);
                        }, () => {
                            this.form.loading = false;
                        });
                    }).catch((error) => {
                        this.$notify({
                            type: 'error',
                            title: this.$t("messages.error"),
                            message: error,
                        });

                        this.form.loading = false;
                    });
                } else {
                    return false;
                }
            });
        },
        async requestVideoUpload(multipleRequest) {
            return new Promise((resolve, reject) => {
                this.axios.all(multipleRequest).then(this.axios.spread((...responses) => {
                    let result = [];

                    for (let i = 0; i < responses.length; i++) {
                        let handleResponse = responses[i].data;

                        if (!handleResponse.status) {
                            break;
                        }

                        result.push(handleResponse.data);
                    }

                    if (result.length == responses.length) {
                        resolve({status: true, data: result});
                    } else {
                        reject('request_count_dont_match_with_response_count');
                    }
                })).catch((errors) => {
                    reject('occurred_any_error');
                })
            });
        },
        handleChangeFile(file, fileList){
            this.form.uploadList = fileList;
        },
        checkVideoWithoutFolder(){
            this.axios.get(this.endpoint.get('video'), {
                params: {
                    directory_id: 0,
                    sort: 'created_at:desc',
                    page_limit: 100
                }
            }).then((response) => {
                let data = response.data.data.map((video) => {
                    video.is_directory = false;

                    return video;
                });

                let meta = response.data.meta;

                if(data.length) {
                    this.directoryTable.data.unshift({
                        id: 0,
                        is_directory: true,
                        title: this.$t("pages.directory.videoWithoutFolder"),
                        children: data,
                        childrenLoaded: true,
                        videos_count: meta.total_record
                    });

                    setTimeout(() => {
                        this.refreshFileManager();
                    }, 0);
                }
            });
        },
    }
}
</script>

<style>
.dx-filemanager .dx-filemanager-files-view.dx-filemanager-details .dx-filemanager-details-item-thumbnail {
    width: 30px;
    height: 30px;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td, .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td{
    vertical-align: middle;
}
</style>